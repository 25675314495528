import Vue from 'vue'
import Vuex from 'vuex'
import { GetFromApi } from '../services/apiClient';
import { InitCountry } from '../services/locator';
import date from 'date-and-time';
import countryJs from 'country-js';
//import currencySymbol from 'currency-symbol';

Vue.use(Vuex)

const storeInSession = (state) => {
  const sessionItem = {
    session: state.sessionId,
    checkin: state.chosenDateFrom,
    checkout: state.chosenDateTo,
    adults: state.occupantsAdults,
    children: state.occupantsChildren,
    currency: state.currencyCode
  }
  localStorage.setItem('preferences', JSON.stringify(sessionItem));
}



export default new Vuex.Store({
  state: {
    sessionId: "notset",
    mapId: null,
    mapConfig: {},
    affId: "xx",
    loadId: null,
    places: {accomPlaces:[], accomPlacesCount: 0},
    loadingPlaces: false,
    pendingPlaceLoad: false,
    chosenDateFrom: null,
    chosenDateTo: null,
    occupantsAdults: 2,
    occupantsChildren: 0,
    centreLat: null,
    centreLng: null,
    zoom: null,
    bounds: null,
    countryCode: null,
    currencyCode: null,
    sourceType: "default",
    priority: "popularity"
  },
  mutations: {    
    setSessionId(state, payload){
      state.sessionId = payload;
    },
    setDates(state, payload){
      state.chosenDateFrom = payload.from;
      state.chosenDateTo = payload.to;
      storeInSession(state);
    },
    setOccupants(state, payload){
      state.occupantsAdults = payload.adults;
      state.occupantsChildren = payload.children;
      storeInSession(state);
    },
    setPriority(state, payload){
      state.occupantsAdults = payload.adults;
      state.occupantsChildren = payload.children;
      //storeInSession(state);
    },
    setEditMapConfig(state, payload){
      state.mapConfig = payload.mapConfig;
      state.affId = payload.affId;
      state.loadId = payload.loadId;
    },
    setPlaces(state, places){
      state.places = places;
    },
    setInitialPosition(state, data){
      state.centreLat = data.lat;
      state.centreLng = data.lng;
      state.zoom = data.zoom;
    },
    updatePosition(state, data){
      if(!data.refreshing){
        state.pendingPlaceLoad = true;
      }
      state.centreLat = data.lat ?? state.centreLat;
      state.centreLng = data.lng ?? state.centreLng;
      state.bounds = data.bounds ?? state.bounds;
    },
    updateBounds(state, data){
      state.bounds = data;
    },
    updateZoom(state, data){
      state.zoom = data.zoom;
     // state.bounds = data.bounds;
    },
    isLoadingPlaces(state){
      state.loadingPlaces = true;
    },    
    hasLoadedPlaces(state){
      state.loadingPlaces = false;
    },
    setCurrencyCode(state, data){
      state.currencyCode = data;         
      storeInSession(state);
    },
    setSourceType(state, data){
      state.sourceType = data;
    }
  },
  getters:{
    getMapId(state){
      return state.mapId;
    },
    getLoadId(state){
      return state.loadId;
    },
    getMapConfig(state){
      return state.mapConfig;
    },
    getPlaces(state){
      return state.places.accomPlaces;//?.slice(0,50);
    },
    isLoadingPlaces(state){
      return state.loadingPlaces;
    },
    isPendingPlaceRefresh(state){
      return state.pendingPlaceLoad;
    },
    canShowMorePlaces(state){
      return state.places.accomPlacesCount
    },
    currentZoom(state){
      return state.zoom ?? state.mapConfig.zoom;
    },
    currentPosition(state){
      return { zoom: state.zoom ?? state.mapConfig.zoom,
               lat: state.centreLat ?? state.mapConfig.lat,
               lng: state.centreLng ?? state.mapConfig.lng
            };
    },
    currencyCode(state){
      return state.currencyCode;
    },
    isNonDecimalCurrency(state){
      return state.places.accomPlaces.every(x=>{const priceString = x.price.toString(); return priceString.match(/^[0-9]+$/) != null && priceString.length > 3;});
    },
    getDatesAndOccupants(state){
      return {
        from: state.chosenDateFrom,
        to: state.chosenDateTo,
        adults: state.occupantsAdults,
        children: state.occupantsChildren
      }
    },
    getSourceType(state){
      return state.sourceType;
    }
   // allCurrencies(state){
    //  return state.currencySymbol;
   // }
  },
  actions:{
    initLocation(context){
      return new Promise((resolve, reject) =>{
        if(context.state.currencyCode){
          return resolve();
        }
        InitCountry().then(result => {
        
      
          context.state.countryCode = result.countryCode;
          const country = countryJs.search(result.countryCode)[0];
          if(country != null){
            context.state.currencyCode = country.currency.currencyCode;
            resolve();
          }
          else{
            context.state.currencyCode = "USD";
            resolve();
          }
        }).catch(e => {
          context.state.currencyCode = "USD";
          reject(e);
        })
      })
      
    },
    loadMapConfig(context, data) {
      return new Promise((resolve, reject) => {
        const config = context.getters.getMapConfig;
          if (config?.id && config.id == data){
            
            resolve(config);
          }
          else{
            context.state.mapId = data;
            //GetFromApi("https://localhost:44369/public/mapconfigs/" + data)
            GetFromApi("https://api.cartogro.com/public/mapconfigs/" + data + `?sessionid=${context.state.sessionId}` + (context.state.loadId ? `&loadid=${context.state.loadId}` : ''))
              .then(response => {
                context.commit('setEditMapConfig', response);
               // context.dispatch('refreshPlaces');
                resolve(response);
              })
              .catch(error => {
               // context.dispatch('refreshPlaces');
                reject(error);
              })     
          }
      })
    },
    refreshPlaces(context, payload) {
      const from = payload?.from ?? context.state.chosenDateFrom;
      const to = payload?.to ?? context.state.chosenDateTo;
      const adults = payload?.adults ?? context.state.occupantsAdults;
      const children = payload?.children ?? context.state.occupantsChildren;
      
      const state = context.state;
     // const lat = payload?.lat ?? state.centreLat ?? state.mapConfig.lat;
     // const lng = payload?.lng ?? state.centreLng ?? state.mapConfig.lng;
     
     const bounds = payload?.bounds ?? state.bounds;

     const isOnInit = payload?.isOnInit ?? false;
     
     const topLat = bounds?._northEast?.lat;
     const bottomLat = bounds?._southWest?.lat;
     const leftLng = bounds?._southWest?.lng;
     const rightLng = bounds?._northEast?.lng;

      
      const ax = rightLng;
      const ay = bottomLat;
      const bx = leftLng;//
      const by = bottomLat;
      const cx = leftLng;
      const cy = topLat;
      const dx = rightLng;//
      const dy = topLat;
      const polygonQueryParams = `ax=${ax}&ay=${ay}&bx=${bx}&by=${by}&cx=${cx}&cy=${cy}&dx=${dx}&dy=${dy}`;
      
      //const zoom = payload?.zoom ?? state.zoom ?? state.mapConfig.zoom;
      if(by==0){
        return;
      }
      //const nextCount = payload?.isMore ? 0 : state.places.accomPlacesCount;

      context.commit('isLoadingPlaces');
      context.commit('setDates', {from: from, to: to});
      context.commit('setOccupants', {adults: adults, children: children});
     // GetFromApi(`https://localhost:44369/public/places/get?loadId=${context.state.loadId}&configId=${context.state.mapId}&${polygonQueryParams}&occupants=${adults}&childOccupants=${children}&checkin=${date.format(from, 'YYYY-MM-DD')}&checkout=${date.format(to, 'YYYY-MM-DD')}&currency=${context.state.currencyCode}`)
     
      GetFromApi(`https://api.cartogro.com/public/places/get?loadId=${context.state.loadId}&configId=${context.state.mapId}&${polygonQueryParams}&occupants=${adults}&childOccupants=${children}&checkin=${date.format(from, 'YYYY-MM-DD')}&checkout=${date.format(to, 'YYYY-MM-DD')}&currency=${context.state.currencyCode}&init=${isOnInit}`)
      .then(response => {
          context.commit('setPlaces', response);
          context.commit('hasLoadedPlaces');
          state.pendingPlaceLoad = false;
        })
        .catch(error => {
          console.log(error);
          context.commit('hasLoadedPlaces');
        })     
      //return new Promise((resolve, reject) => {
      //  //GetFromApi("https://localhost:44369/public/mapconfigs/" + data)
      //  GetFromApi("https://api.cartogro.com/public/places/forarea")
      //  .then(response => {
      //    context.commit('setPlaces', response);
      //    resolve(response);
      //  })
      //  .catch(error => {
      //    reject(error);
      //  })     
      //})
    },
    trackEvent(context, payload){
      GetFromApi(`https://api.cartogro.com/public/track/${context.state.mapId}/referral/${context.state.loadId}/?referralRef=${payload.ref}&sessionId=${context.state.sessionId}&value=${payload.value}`)
        .then(() => {
         // console.log(response);
        })
        .catch(error => {
          console.log(error);
        })     
    }
  },
  modules: {
  }
})
