<template>
  <div id="app" :style="'position: relative; height: 100%'">
    <div v-if="!configLoaded">
      <b-loading v-model="showSpinner" :can-cancel="true"></b-loading>

    </div>
    <div class="columns is-mobile is-gapless top-container">
      <div class="column is-one-half">
        <!--<HotelDatePicker />-->
         <b-datepicker
           :icon="'calendar'"
            style="z-index: 9999;"
            :mobile-native="false"
            :min-date="( d => new Date(d.setDate(d.getDate()-1)) )(new Date)"
                placeholder="Select dates..."
                v-model="dates"
                range>
            </b-datepicker>
      </div>
      <div class="column">
          <b-collapse :open.sync="peoplePickerOpen" aria-id="contentIdForA11y1" ref="peopleCollapse" @close="closePeople" style="z-index:9999; position: relative;">
            <template #trigger>                

                <b-input
                    class="people-btn"
                    :placeholder="personsText"
                    icon="user" ></b-input>

            </template>
            <div class="box people-picker-popup" >
              <div class="occupant-row">
                <span class="occupant-label">Adults</span>
                <b-button icon-left="minus" @click="adults--" :disabled="adults < 2" type="is-primary"></b-button>
                <span class="occupant-value">{{adults}}</span>
                <b-button icon-left="plus" @click="adults++" type="is-primary"></b-button>
              </div>
              <div class="occupant-row">
                <span class="occupant-label">Children</span>
                <b-button icon-left="minus" @click="children--" :disabled="children < 1" type="is-primary"></b-button>
                <span class="occupant-value">{{children}}</span>
                <b-button icon-left="plus" @click="children++" type="is-primary"></b-button>
              </div><!--
              <b-field label="Adults" >
                          <b-numberinput 
                              :min="0"
                              v-model="adults"
                              icon="user-friends">
                          </b-numberinput >
                      </b-field>
              <b-field label="Children" >
                          <b-numberinput 
                              :min="0"
                              v-model="children"
                              icon="child">
                          </b-numberinput >
                      </b-field>-->
              <b-field class="people-apply-btn"><b-button @click="applyPeople" expanded type="is-primary">Update</b-button></b-field>
            </div>
        </b-collapse>

      </div>
    </div>
    <MapComponent :loading="isLoadingPlaces()" @anywhereClick="onMapAnywhereClick"/>
    </div>
</template>

<script>
import MapComponent from '../../components/MapComponent.vue'
 import { mapActions, mapMutations, mapGetters } from 'vuex';
 import date from 'date-and-time';
  import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'App',
  data(){
    return {
      dates: [],
      adults: 2,
      children: 0,
      configLoaded: false,
      showSpinner: true,
      peoplePickerOpen: false,
      peopleChanged: false
    }
  },
  computed: {
    personsText: function(){
      return `${this.adults} adults - ${this.children} children`
    }
    //isLoaded: function() {
    //  return this.loaded;
   // }
  },
  methods:{
    ...mapGetters(['isLoadingPlaces']),
    ...mapActions(['loadMapConfig', 'refreshPlaces', 'initLocation']),
    ...mapMutations(['setDates', 'setOccupants', 'setInitialPosition', 'setSessionId', 'setSourceType','setCurrencyCode']),
    applyPeople(){
      this.$refs.peopleCollapse.toggle();
    },
    closePeople(){
      if(this.peopleChanged){
        this.refreshPlaces({adults: this.adults, children: this.children});
        this.peopleChanged = false;
      }
     
    },
    onMapAnywhereClick(){
      if(this.peoplePickerOpen){
        this.$refs.peopleCollapse.toggle();
      }
      
    }
  },
  watch:{
    adults: function(newValue, oldValue){
      if(newValue != oldValue){
        this.peopleChanged = true;
      } 
    },
    children: function(newValue, oldValue){
      if(newValue != oldValue){
        this.peopleChanged = true;
      } 
    },
    dates: function (newValue, oldValue) {
      if(newValue != oldValue && this.configLoaded){
        //this.setDates({from: newValue[0], to: newValue[1]}); refresh places sets this
        this.refreshPlaces({from: newValue[0], to: newValue[1]});
      }    
     
    },
  },
  beforeMount() {
    

    const params = new URLSearchParams(window.location.search);

    const sessionStorageEntry = localStorage.getItem('preferences');
    
    const hasSessionStorage = !!sessionStorageEntry;
    const sessionStorageItem = hasSessionStorage ? JSON.parse(sessionStorageEntry) : {};
    

    if(hasSessionStorage){
      this.setSessionId(sessionStorageItem.session);
      if(sessionStorageItem.currency){
      this.setCurrencyCode(sessionStorageItem.currency);
    }
    }
    else{
      this.setSessionId(uuidv4());
    }
    const now = new Date();
    now.setHours(0,0,0,0);
    
    let storageIsInPast = false;
    let checkinDate;
    if(hasSessionStorage){
      checkinDate = new Date(sessionStorageItem.checkin);
      storageIsInPast = checkinDate < now;
    }
    

    const initialCheckin = hasSessionStorage && !storageIsInPast ? checkinDate : date.addMonths(now, 1);
    const initialCheckout = hasSessionStorage && !storageIsInPast ? new Date(sessionStorageItem.checkout) : date.addDays(initialCheckin, 2);
  
    const fromParam = params.get('checkin');
    const toParam = params.get('checkout');
    const fromParamDate = new Date(fromParam);

    const sourceParam = params.get('from');
    if(sourceParam){
      this.setSourceType(sourceParam);
    }   
    
    const suggestedStart = fromParam ? fromParamDate : initialCheckin;
    const suggestedEnd = toParam ? new Date(toParam) : initialCheckout;
    this.dates = [suggestedStart, suggestedEnd];
    this.setDates({from: suggestedStart, to: suggestedEnd});

    const adultsParam = params.get('adults');
    const childrenParam = params.get('children');
    if(adultsParam && childrenParam){
      this.adults = adultsParam;
      this.children = childrenParam;
      this.setOccupants({adults: adultsParam, children: childrenParam});
    }  
    else if(hasSessionStorage){
      this.adults = sessionStorageItem.adults;
      this.children = sessionStorageItem.children;
      this.setOccupants({adults: sessionStorageItem.adults, children: sessionStorageItem.children});
    }

    const lat = params.get('lat');
    const lng = params.get('lng');
    const zoom = params.get('zoom');
    //const load = params.get('load'); //pass loadId to loadMapConfig() and dont create a new Load on the backend
    if(lat && lng && zoom){
      this.setInitialPosition({lat, lng, zoom});
    }
    const mapConfigId = params.get('id');
    this.initLocation().then(() =>{
      if(mapConfigId){
      this.loadMapConfig(mapConfigId)
        .then(() => {
     //      this.loaded = true;
          this.configLoaded = true;
        })
        .catch(err => {
          console.log(err);
        })
    }    
    }).catch(error =>{
      console.log(error);
      if(mapConfigId){
      this.loadMapConfig(mapConfigId)
        .then(() => {
     //      this.loaded = true;
          this.configLoaded = true;
        })
        .catch(err => {
          console.log(err);
        })
      }
    });
    
  },
  components: {
    MapComponent,
   // HotelDatePicker
  }
}
</script>

<style scoped>  
.occupant-row{
    padding: 5px 0;
  }

  .occupant-value{
    display: inline-block;
    font-size: 26px;
    width: 80px;
    text-align: center;
    padding: 0px;
  }

  .occupant-label{
    display: inline-block;
    font-size: 20px;
    width: 90px;
    padding: 0px;
  }
</style>


<style lang="scss">

.datepicker .dropdown .input{  
  color: #808080 !important;
}

.datepicker-cell.is-selected.is-first-selected, .datepicker-cell.is-selected.is-last-selected {
  background-color: #0267c1 !important;
}

.datepicker-cell.is-selected.is-within-selected {
  background-color: #70b0ff !important;
}

.datepicker-cell.is-today{
  border: 1px solid #dddddd !important;
}

.datepicker .input{
  border-radius: 0px !important;
  color: #777777 !important;

  @media only screen and (max-width: 479px) {
    font-size: 12px !important;
    padding-top: 19px !important;
    padding-bottom: 19px !important;
    text-align: center !important;
  }
}

.datepicker .input::placeholder{
  //color: #0B3954 !important;
  color: #000000 !important;
}

.datepicker .icon{
 // color: #0267c1 !important;
  color: #0B3954 !important;
}

.people-btn input{
  border-radius: 0px !important;
  border: 1px 1px 1px 0 solid rgba(54, 54, 54, 0.2) !important;
  width:100%;
  background-color: #ffffff !important;
  cursor: pointer !important;
  pointer-events:none !important;

  @media only screen and (max-width: 479px) {
    font-size: 12px !important;
    padding-top: 19px !important;
    padding-bottom: 19px !important;
    text-align: center !important;
  }
}

.people-btn input:hover{
  border: 1px solid #d8d8d8 !important;
}

.people-btn input::placeholder{
  color: #000000 !important;
}


.people-btn .icon{
  color: #0B3954 !important;
}

.people-apply-btn {
  margin-top: 25px;
}

.people-picker-popup{

  position: absolute; 
  z-index: 99999; 
  @media only screen and (max-width: 582px) {
    width: 300px; 
    left: -150px; 
    top: 50px;
  }
}

.top-container {
  position: absolute;
  width: 100%;
  margin-bottom:0px !important;
}

</style>

<style>
  html, body {
    height: 100%;
  }
  .leaflet-top {
    top: 40px;
  }
</style>