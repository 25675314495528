
import axios from "axios";

  
const getQueryStringValue = function(parameter) {
  var currentPageURL = window.location.search.substring(1);
  var queryString = currentPageURL.split('&');
  var getParameter;
  var i;
  for (i = 0; i < queryString.length; i++) {
      getParameter = queryString[i].split('=');
      if (getParameter[0] === parameter) {
          return getParameter[1] === undefined ? true : decodeURIComponent(getParameter[1]);
      }
  }
};


export const GetFromApi = async (path) =>{
    const { data } = await axios.get(path, {
      headers: {
        ParentReferer: document.referrer,
        Demo: getQueryStringValue('isdemo') === 'true' ? 'true' : 'false'
      }
    })
      .catch((err)=> { console.log(err); });
    return data;
    //return await fetch(path, {
    //    method: 'GET',
    //    headers: getRequestHeadersWithAuth(token)
//,   //     })
}

