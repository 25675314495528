import Vue from 'vue'
import App from './App.vue'
import store from '../../store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
//import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free/js/all.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

require('@/assets/main.scss');

Vue.use(Buefy, { defaultIconPack: 'fas',  defaultIconComponent: FontAwesomeIcon });
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
