<template>
<!--<div class="page-wrapper" :style="'height: 100vh; position: relative; overflow-y:hidden; width: 100%'">-->
 <div class="page-wrapper" :style="'height: 100%; width: 100%; overflow:hidden; position: relative;'">
  
 <b-button v-if="getSourceType() != 'link'" @click="maximizeMap()" icon-left="expand-alt" style="position: absolute; top: 50px; right: 10px; z-index:999" />

  <CurrencyPopup :shouldShow="showCurrencyModal" @closeCurrencyModal="showCurrencyModal = false"/>
  
  <l-map
  v-if="getMapConfig().id != null"
      ref="map"
      :key="getMapConfig().id"
      :zoom="initialZoom()"
      :center="initialCentre()"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @ready="mapInitialized"
      @click="onMapComponentClick"
    >

  <l-tile-layer
        ref="layer"
        :url="mapTilesUrl"
        :attribution="attribution"
      />      
  
  <div v-for="pin in getMapConfig().pins" :key="pin.id">
    <map-marker
        :latLng="getLatLngFromPin(pin)"        
        :color="pin.color"
        :icon="pin.icon"
        :title="pin.title"
        :description="pin.description"
        @moveTo="moveToLocation(pin.lat, pin.lng, 12)"
        />
      </div>
      
      <div>
  <div v-for="pin in getPlaces()" :key="pin.id">
    <map-place-marker-v2
        :latLng="getLatLngFromPin(pin)"        
        :place="pin"
        />
      </div>
   </div>
   
  <l-geo-json v-for="(item, index) in getRoute" :geojson="item.geoJson ? item.geoJson : item.geoJsonLine" :key="`journeyleg-${index}`"  :options-style="routeStyles()" :componentKey="componentKey"></l-geo-json>
  <div v-if="getMapConfig().lineRoute">
  <l-geo-json v-for="(item, index) in getLineRoute" :geojson="item.geoJson ? item.geoJson : item.geoJsonLine" :key="`linejourneyleg-${index}`"  :options-style="routeStyles()" :componentKey="componentKey"></l-geo-json>
</div>

  <l-geo-json v-if="hasArea" :geojson="areaGeoJson" :options-style="areaStyles()" ></l-geo-json>

   </l-map>
   <!--

            <b-select v-model="selectedPriority" class="priority-picker" size="is-small"  placeholder="Search by..">
                <option
                    v-for="option in priorityOptions"
                    :value="option.key"
                    :key="option.key">
                    {{ option.label }}
                </option>
            </b-select>-->

   <b-button @click="showCurrencyModal = true" style="position: absolute; right:5px; bottom: 30px; z-index:999" type="is-small">{{currencyCode()}} / <strong v-html="currentCurrencySymbol"/></b-button>
  
<div class="refresh-btn-container">
   <!--<b-button v-if="loading" class="refresh-btn" loading>Searching...</b-button>-->
   <div class="zoom-hint" v-if="loading  && currentZoom() > 10" >
      <div style="margin-bottom: 5px;">Finding accommodation</div>
      <b-progress  style="margin-bottom: 2px;" size="is-small" type="is-primary"></b-progress>

   </div>
   <b-button icon-left="search" @click="triggerRefreshPlaces()" v-if="!loading && isPendingPlaceRefresh() && currentZoom() > 10" class="refresh-btn" type="is-primary">Search Area</b-button>
   <!--<b-button icon-left="search-plus" @click="refreshPlaces({isMore: true})" v-if="!loading && !isPendingPlaceRefresh() && !isLoadingPlaces()" class="refresh-btn" type="is-primary">Search More</b-button>-->
   <div class="zoom-hint" v-if="currentZoom() <= 10">Zoom in or select marker to find nearby accommodation</div>
   <!--<b-button class="refresh-btn" disabled>Zoom in to find accommodation</b-button>-->
</div>

   
   <a href="https://www.cartogro.com" target="_blank" class="map-logo">
   <div style="font-size: 8px; font-weight: 700; color: #444f60;">Powered by</div>
   <img class="cartogro-logo-img"
                    src="../assets/cartogro-dark.svg"
                    alt="Cartogro.com"
                ></a>
    <div class="kayak-logo">
      <div class="kayak-logo-text">Hotels &amp; Rentals from</div>
    <svg class="svg-image kayak-logo-image" role="img" viewBox="0 0 125 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 24h23.936V0H0v24zm25.266 0h23.936V0H25.266v24zm49.202 0H50.532V0h23.936v24zm1.33 0h23.936V0H75.798v24zM125 24h-23.936V0H125v24z" fill="#FF690F"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.287 11.549V6H7.38v12h2.907v-5.548L13.348 18h3.208l-3.33-6 3.33-6h-3.208l-3.061 5.549zm24.785 4.724L34.552 18h-3.104l3.947-12h3.696l3.93 12h-3.194l-.52-1.727h-4.235zm2.117-6.837l-1.4 4.48h2.8l-1.4-4.48zM63.98 18h-2.906v-5.29L57.144 6h3.463l1.938 3.688L64.447 6h3.409l-3.876 6.71V18zm21.624-1.727L85.084 18h-3.105l3.948-12h3.696l3.93 12h-3.194l-.52-1.727h-4.235zm2.117-6.837l-1.4 4.48h2.8l-1.4-4.48zm23.63 2.113V6h-2.907v12h2.907v-5.548L114.412 18h3.208l-3.33-6 3.33-6h-3.208l-3.061 5.549z" fill="#FAFAFC"></path></svg>
  </div>
</div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import MapMarker from './MapMarker';
 import MapPlaceMarkerV2 from './MapPlaceMarkerV2';
  import CurrencyPopup from './CurrencyPopup';
 import { mapGetters, mapMutations, mapActions } from 'vuex';
  import 'leaflet/dist/leaflet.css';
  import date from 'date-and-time';
  
import currencySymbol from 'currency-symbol';

export default {
  name: 'MapComponent',
  props: {
    loading: Boolean
   // msg: String
  },
  methods:{
    ...mapGetters(['getMapId','getLoadId','getMapConfig', 'getPlaces', 'isPendingPlaceRefresh', 'isLoadingPlaces','currentZoom', 'currentPosition', 'currencyCode', 'getDatesAndOccupants', 'getSourceType']),
    ...mapMutations(['updatePosition','updateZoom', 'updateBounds']),
    ...mapActions(['refreshPlaces']),
    moveToLocation(lat, lng, zoom){
      const dynamicZoom = this.currentZoom() + 2;
      if(dynamicZoom > zoom){
        zoom = dynamicZoom;
      }
      this.currentlyMovingAndRefreshing = true;
      this.$refs.map.mapObject.setView([lat, lng], zoom);  
    },
    getLatLngFromPin(pin){
      return latLng(pin.lat, pin.lng);
    },
    getLatLngFromPin2(pin){
      return latLng(pin.lat + 0.011, pin.lng + 0.03);
    },
    mapInitialized(){
      const bounds = this.$refs.map.mapObject.getBounds();
      this.updateBounds(bounds);
      var places = this.getPlaces();
      
      if((!places || places.length === 0) && this.currentZoom() > 10){        
        this.refreshPlaces({zoom: this.currentZoom(), bounds, isOnInit: true});
      }
      else{
        this.updateBounds(bounds);
      }
    },
    triggerRefreshPlaces(){
      const bounds = this.$refs.map.mapObject.getBounds();
        this.refreshPlaces({zoom: this.currentZoom(), bounds});
    },
    centerUpdate(position){      
      
      //position.refreshing = this.currentlyMovingAndRefreshing;
      const bounds = this.$refs.map.mapObject.getBounds();
      position.bounds = bounds;
      this.updatePosition(position);
      
      if(this.currentlyMovingAndRefreshing){
        this.currentlyMovingAndRefreshing = false;
        
         this.refreshPlaces({zoom: 12, bounds});
      }
      
    },
    zoomUpdate(zoom){
      //const bounds = this.$refs.map.mapObject.getBounds();
      this.updateZoom({zoom});
    },
    routeStyles() {
      const fillColor = this.getMapConfig().routeColor; //??? important! need touch fillColor in computed for re-calculate when change fillColor
      const weight = this.getMapConfig().routeLineWeight;
      return {
          weight: weight,
          color: fillColor,
         // opacity: 1,
         // fillColor: fillColor,
         // fillOpacity: 1
        };
    },
    areaStyles() {
      const outlineColor = this.getMapConfig().area.outlineColor; //??? important! need touch fillColor in computed for re-calculate when change fillColor
      const outlineWeight = this.getMapConfig().area.outlineWeight;
      const fillColor = this.getMapConfig().area.fillColor;
      const fillOpacity = this.getMapConfig().area.fillOpacity;
      return {
          weight: outlineWeight,
          color: outlineColor,
         // opacity: 1,
          fillColor: fillColor,
          fillOpacity: fillOpacity
        };
    },   
    maximizeMap(){
      const datesOccupants = this.getDatesAndOccupants();
      const formattedDateFrom = date.format(datesOccupants.from,'YYYY-MM-DD');
      const formattedDateTo = date.format(datesOccupants.to,'YYYY-MM-DD');
      window.open(`https://maps.cartogro.com?id=${this.getMapId()}&load=${this.getLoadId()}&lat=${this.currentPosition().lat}&lng=${this.currentPosition().lng}&zoom=${this.currentPosition().zoom}&checkin=${formattedDateFrom}&checkout=${formattedDateTo}&adults=${datesOccupants.adults}&children=${datesOccupants.children}`);
    } ,
    initialCentre(){
      return latLng(this.currentPosition().lat, this.currentPosition().lng);
    },
    initialZoom(){
      return parseInt(this.currentPosition().zoom);
    },
    onMapComponentClick(){
      this.$emit('anywhereClick');
    }
  },
  emit:['anywhereClick'],
  data() {
    return {
      showCurrencyModal: false,
      currentlyMovingAndRefreshing: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a>',
      //withPopup: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      //withTooltip: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      //currentCenter: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      //showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      //showMap: true,
      //mapBounds: null,
      selectedPriority: "popularity",
      priorityOptions:[
        {
          key: "popularity",
          label: "Most popular"
        },
        {
          key: "minRate",
          label: "Lowest price"
        },
        {
          key: "rating",
          label: "Star rating"
        },
        {
          key: "consumerRating",
          label: "Customer rating"
        },
      ]
      
    };
  },
  computed: {    
    currentCurrencySymbol: function(){
      return this.currencyCode() ? currencySymbol.symbol(this.currencyCode()) : "";
    },
    //center: function(){
    //    return latLng(this.getMapConfig().lat, this.getMapConfig().lng)
    //  },
    hasArea: function(){
      return this.getMapConfig().area?.geoJsonCoordinates;
    },
    areaGeoJson: function(){
      if(this.hasArea && this.getMapConfig().area.geoJsonCoordinates){
        return {
          "type": "Polygon",
             "coordinates": [this.getMapConfig().area.geoJsonCoordinates]
        }
      }

     // if(this.hasArea && this.currentAreaStartPoint){
     //   return {
     //        "type": "LineString",
     //        "coordinates": this.areaPaths
     //       }
     // }
      return null;    
      
    },
    getRoute: function(){
      return this.getMapConfig().route != null 
        ? this.getMapConfig().route.filter(x=>x.geoJson != null || x.geoJsonLine != null)
        : [];
    },
    getLineRoute: function(){
      return this.getMapConfig().lineRoute != null 
        ? this.getMapConfig().lineRoute.filter(x=>x.geoJson != null || x.geoJsonLine != null)
        : [];
    },
    mapTilesUrl: function(){
      if(this.getMapConfig().mapStyle == 'Standard'){
        return 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Street'){
        return 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Satellite'){
        return 'https://api.maptiler.com/tiles/satellite-mediumres-2018/{z}/{x}/{y}.jpg?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Outdoor'){
        return 'https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Topographic'){
        return 'https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Winter'){
        return 'https://api.maptiler.com/maps/winter/{z}/{x}/{y}.jpg?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Bright'){
        return 'https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Pastel'){
        return 'https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Hybrid'){
        return 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=XQkcBPA5hzBYzx1MW9M8';
      }
      return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    },
  },  
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    MapMarker,
    MapPlaceMarkerV2,
    CurrencyPopup
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.priority-picker{
  position: absolute; 
  left:55px; 
  top: 51px; 
  z-index:999;

  @media only screen and (max-width: 479px) {
    left:65px; 
    top: 51px; 
    margin: 0 auto;
  }
}

.zoom-hint{
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  background: rgba(0,0,0,0.45);
  padding: 5px 10px;
  border-radius: 5px;

  @media only screen and (max-width: 479px) {
    width: 170px;
  }
}

 .refresh-btn-container{
   position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 990;
 }
.refresh-btn{
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  max-width: 140px;
}

.map-logo{
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 2px 8px 0px 6px;
  background-color: #ffffff;
  border-radius: 0 10px 0 0;
  z-index: 999;
}

.kayak-logo{
  display: block;
  position: absolute;
  bottom: 0px;
  left: 105px;
  width: 85px;
  z-index: 999;

  @media only screen and (max-width: 479px) {
    width: 60px;
    left: 5px;
    bottom: 42px;
  }
}

.cartogro-logo-img{
  width: 80px;
  @media only screen and (max-width: 479px) {
     width: 60px;
  }
}

.kayak-logo-img{
  width:85px;
  line-height:inherit;
  color:inherit;
  @media only screen and (max-width: 479px) {
    width: 60px;
    left: 5px;
    bottom: 42px;
  }
}

.kayak-logo-text{
  position: relative;
  font-size: 8px; 
  color: #444f60; 
  @media only screen and (max-width: 479px) {
    font-size: 6px;
    margin-bottom: -4px;
  }
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
