<template>
<l-marker  ref="marker" :lat-lng="latLng">

  
         <l-icon
          class-name="marker-item"
          :icon-anchor="[17,22]"
        >
        
        <!--<div v-if="showTitle" class="pin-title">{{title}}</div>-->
          <div class="headline inner-pin-con">
            <div class="inner-pin" :style="{'width': labelWidth + 'px', 'left': labelOffset + 'px', 'height': '31px', 'border-color': providerColor}">
              <!--<b-icon
                pack="fas"
                icon="moon"
                size="is-small"
                style="display: inline">
            </b-icon>-->
            <img v-if="place.providerLogo" :style="{'left': logoOffset + 'px'}" style="width: 50px; position: relative;"  :src="place.providerLogo"/>
            <div v-if="!place.providerLogo" style="font-size: 7px; height: 16px; color: #444f60; font-weight: normal;">Book Direct</div>
            <!--<div v-if="!place.providerLogo" style="font-size: 7px; height: 16px; color: #444f60; font-weight: normal; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; text-align: center">{{place.name}}</div>-->
              <span style="position: relative; top: -5px" v-html="`${pinCurrencySymbol}${formattedPrice}`"></span>
            </div>
                <div class='pin' :style="`background-color:${providerColor}`">
                  
                </div>
              </div>
          </l-icon>
     <l-popup :options="{offset:[-1,15] }" class="ft-placemarker-container"  :style="place.image != null ? `background: url(${place.image}); background-size: auto 160px; border: 2px solid ${providerColor}; background-position: 0 0px;`:'border: 2px solid ${providerColor}; background-position: 0 0px;'">
          <div class="popup-content" @click="referTo(place.link)">

            <div class="provider-logo-container" :style="`position: absolute; top: -15px; left: -5px; background:#ffffff; border-radius: 5px; border:1px solid ${providerColor}; height:21px;`">
            <img v-if="place.providerLogo" style="width: 80px;"
                    :src="place.providerLogo"
                >
                
            <div v-if="!place.providerLogo" style="text-align: center; margin-top: 1px; font-size: 12px; height: 16px; width: 80px; color: #444f60; font-weight: normal;">Book Direct</div>
          </div>
            <div class="popup-content-top">
              <div  style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; height: 18px;"><strong>{{place.name}}</strong></div>
              
                <div><star-rating :rating="place.rating / 2" :increment="0.1" :read-only="true" :star-size="12" :show-rating="true" :inline="true"/>
                <span class="rating-reviews">({{place.ratingCount}})</span></div>
              
            </div>

            <div v-if="place.isFreeCancellation" style="background-color: #11BB33; padding: 0 2px; color: #ffffff; border-radius: 0 0 5px 0; font-size:10px; font-weight: bold; width: 112px; position: absolute;">FREE CANCELLATION</div>
            <div v-if="place.isPayLater && !place.isFreeCancellation" style="background-color: #2ec12a; padding: 0 2px; color: #ffffff; border-radius: 0 0 5px 0; font-size:10px; font-weight: bold; width: 62px; position: absolute;">PAY LATER</div>
            
            <div v-if="place.isGreatValue && !place.isPayLater && !place.isFreeCancellation" style="background-color: #a42b87; padding: 0 2px; color: #ffffff; border-radius: 0 0 5px 0; font-size:10px; font-weight: bold; width: 80px; position: absolute;">GREAT VALUE</div>
            
            <div v-if="place.starRating == 5 && !place.isGreatValue && !place.isPayLater && !place.isFreeCancellation" style="background-color: #edc42d; padding: 0 2px; color: #ffffff; border-radius: 0 0 5px 0; font-size:10px; font-weight: bold; width: 90px; position: absolute;">5 STAR LUXURY</div>
            <div v-if="place.rating >8 && place.starRating != 5 && !place.isGreatValue && !place.isPayLater && !place.isFreeCancellation" style="background-color: #007fff; padding: 0 2px; color: #ffffff; border-radius: 0 0 5px 0; font-size:10px; font-weight: bold; width: 65px; position: absolute;">TOP RATED</div>
            
            <!--
            <div v-if="place.starRating == 4 && place.rating <= 8 && !place.isGreatValue && !place.isPayLater && !place.isFreeCancellation" style="background-color: #fca81e; padding: 0 2px; color: #ffffff; border-radius: 0 0 5px 0; font-size:10px; font-weight: bold; width: 90px; position: absolute;">4 STAR LUXURY</div>
            -->

            <div v-if="place.availableRooms && place.availableRooms < 9" style="background-color: #FF2040; padding: 0 5px; color: #ffffff; border-radius: 8px; font-size:10px; position: absolute; bottom: 50px; right:6px; display:inline-block">Only <span style="font-weight: bold; font-size: 12px">{{place.availableRooms}}</span> rooms left!</div>
            <div class="popup-content-bottom">

              <div v-html="place.description" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; height: 18px;"></div>
              
              <div>              
                
                <span v-if="place.rooms == 1" class="popup-price-span"><strong v-html="`${pinCurrencySymbol}${formattedPriceFull}`"></strong> <span style='font-size: 8px'>{{place.nights}} nights inc. fees</span></span>
                <span v-if="place.rooms > 1" class="popup-price-span"><span style='font-size: 8px'>{{place.rooms}} rooms, {{place.nights}} nights approx</span> <strong v-html="`${pinCurrencySymbol}${formattedPriceFull}`"></strong></span>

                
                <a class="popup-price-link" :style="`display: inline-block; background: ${providerColor}; border-radius: 3px; padding: 0px 4px; color:#ffffff`" >

                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10px" viewBox="0 0 498.455 498.455" style="enable-background:new 0 0 498.455 498.455;" xml:space="preserve">
<g>
	<g>
		<path fill="#ffffff" d="M492.201,111.423L389.768,46.398c-8.338-5.278-15.146-1.53-15.146,8.338v23.333
			c-85.223,0.459-154.225,69.691-154.225,155.065c0,14.917,2.142,29.376,6.12,43.07c18.513-64.413,77.8-111.614,148.105-111.996
			v23.333c0,9.945,6.809,13.617,15.146,8.338l102.434-65.025C500.615,125.346,500.463,116.702,492.201,111.423z"/>
		<path fill="#ffffff" d="M376.533,379.173c0,11.858-9.717,21.497-21.496,21.497H75.276c-11.934,0-21.497-9.716-21.497-21.497V207.048
			c0-11.857,9.715-21.497,21.497-21.497h136.399c5.891-19.813,14.382-38.021,25.475-53.779H21.496
			C9.639,131.772,0,141.335,0,153.269v279.685c0,11.857,9.715,21.496,21.496,21.496h387.32c11.857,0,21.496-9.562,21.496-21.496
			V189.683l-53.779,34.196V379.173L376.533,379.173z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

                </a>
                <!--<a class="popup-price-link" :style="{'color': providerColor}">Book Now</a>-->
</div>
            </div>
          </div>
        </l-popup>
      </l-marker> 
</template>

<script>
import { LMarker, LIcon, LPopup } from "vue2-leaflet";
import StarRating from 'vue-star-rating';
 import { mapGetters, mapActions } from 'vuex';
 import currencySymbol from 'currency-symbol';
 import { v4 as uuidv4 } from 'uuid';
export default {
  name: "MapPlaceMarkerV2",
  components: {
    LMarker,
    LPopup,
    LIcon,
    StarRating
  },
  props: {
    place: Object,
    latLng: Object
  },
  data() {
    return {
    };
  },
  computed: {   
      pinCurrencySymbol: function(){
        return currencySymbol.symbol(this.place?.currency) ?? this.place?.currency + " ";
      },
      labelWidth: function(){
        const symbol = currencySymbol.symbol(this.place?.currency);

        if(!symbol){
           return !this.place ? 0  : ((`${this.formattedPrice}`.length+4) * 3.5) + 35;
        }

        const charMatches = (symbol.match(/&#/g) || []).length;
        const symbolWidth = charMatches * 6;
        return !this.place ? 0  : (`${this.formattedPrice}`.length * 3.5) + 35 + symbolWidth;
      },
      labelOffset: function(){
        const val = `${this.formattedPrice}`.length * 3.5;
        return !this.place ? 0  : (0 - (val/2)) - 10;
      },
      logoOffset: function(){
        const val = `${this.formattedPrice}`.length * 1.5;
        return -7 + val;
      },
      formattedPrice: function(){
        return (Math.round(this.place.priceNightlyAvg * 100) / 100).toFixed(this.isNonDecimalCurrency() ? 0 : 0);// change to 0:2 to revert
      },
      formattedPriceFull: function(){
        return (Math.round(this.place.priceFull * 100) / 100).toFixed(this.isNonDecimalCurrency() ? 0 : 0);
      },
      providerColor: function(){
        var providerCode =this.place.providerCode;
        return this.mapProviderColor(providerCode);
      }
      //pinLatLng: function(){
      //    return latLng(pin.lat, pin.lng);
      //} 
  },
  methods: {
    ...mapGetters(['isNonDecimalCurrency']),
    ...mapActions(['trackEvent']),
    referTo(rawUrl){
      const newRefId = `R${uuidv4()}`;
      this.trackEvent({ref: newRefId, value: `${encodeURIComponent(this.place.name)}-${this.place.currency}-${this.place.price}`});
      const formattedUrl = rawUrl.replace("[CLICKOUTID]",newRefId);
      //alert(formattedUrl);
      window.open(formattedUrl);
    },
    mapProviderColor(code){
      switch(code){
        case "ACC": return '#1E1852';// accor
        case "BKS": return '#003580';// booking.com
        case "AGD": return '#e12d2d';// agoda
        case "BOO": return '#000000';// Revato - book with hotel
        case "CTE": return '#3264ff';// CTrip (trip.com in sandbox)
        case "EBX": return '#2fb4ed';// ebookers
        case "EXP": return '#343b53';// expedia
        case "GAR": return '#6bbe56';// getaroom
        case "HCM": return '#000000';// hotel website      
        case "HLT": return '#000000';// hilton
        case "HNJ": return '#d02b31';// hotelNjoy
        case "HOA": return '#18c1d9';// hotelopia
        case "HPA": return '#1565ae';// hotelPass
        case "IAN": return '#d32f2f';// hotels.com
        case "ITP": return '#e25147';// interpark
        case "JAL" : return "#e77611";// jalan.net
        case "JAP" : return "#e77611";// jp.jalan.net
        case "MDT" : return "#2BD493";// modetour
        case "ORB" : return "#00253c";// orbitz
        case "OSB" : return "#1299dd";// ostrovok
        case "PRS" : return '#e84d92';// prestigia
        case "SBO" : return "#e32185";// sembo.se
        case "TBM" : return "#FF375C";// tripBtoz.co/members
        case "TBZ" : return "#FF375C";// tripBtoz.com
        case "TCY" : return "#0D5AB9";// travelocity
        case "WOT" : return "#2182fc";// wingontravel
        case "WTF" : return "#7AC231";// wotif travel
        case "YNL" : return "#de2e5f";// yanolja.co.kr
        case "ZHT" : return '#f56f6b';// zenhotels

       // case "SKH": return '';
       
        
       // case "VVS": return '';
       
        case "ECT": return '#ff4800';
       // case "HPO": return '';
        case "LMN": return '#F2007D';
       // case "ELV": return '';
        
        case "HRS": return '#ce0e2d';
        
        
       // case "SBK": return '';
       
        case "HXT": return '#542e91';
       // case "OLO": return '';
        case "LRM": return '#411485';
        
        case "ASR": return '#411485';
        
        
        //case "HPR": return '';
        case "TCK": return '#000000';
       // case "H4U": return '';
        case "GST": return '#F36518';
        case "HDE": return '#FF7500';
        case "HCL": return '#237fa6';
       // case "OHT": return '';
        

        //not checked:
        //case "AHO": return '';
        //case "EVI": return '';
        //case "TCY": return '';
        //case "HPW": return '';
        //case "ORB": return '';
        case "MAR": return '#000000';
        case "HUS": return '#0045AB';
        //case "BVJ": return '';
        default: return '#606060'
        
      }
    }
  }
}
</script>


<style scoped>

.pin-title{
  margin-left:25px;
}

.inner-pin
{
  background:#ffffff;
  border: 2px solid #0c3b7c;
  position: absolute;
  top: -15px;
  left: -11px;
  font-size: 10px;  
  font-weight: bold;
  padding: 1px;
  text-align: center;
  z-index: 9;
  width:57px;
  border-radius: 10px;
}

.popup-content{
  cursor: pointer;
  min-height: 150px;
}

.popup-content-top{
  color: #ffffff;
  padding:5px;
  background: rgba(0,0,0,0.45) !important;
}

.popup-content-top strong{
  color: #ffffff;
}

.popup-content-bottom{
  margin-top: 67px;
  color: #ffffff;
  padding:5px;
  background: rgba(0,0,0,0.45) !important;
}

.popup-price-logo{
  margin-right: 5px;
}

.popup-price-span{
  margin-right: 5px;
}


.popup-price-span strong{
  color: #ffffff;
}

.popup-price-link{
  /*color: #3297ff;
  font-weight: bold;
  text-shadow: -1px -1px 1px rgba(0,0,0,.1), 1px 1px 1px rgba(0,0,0,.5), 1px 1px 0px rgba(0,0,0,0);*/
  position: absolute;
  bottom: 6px;
  right: 8px;
}

.popup-content:hover .popup-price-link, .popup-content:hover  .provider-logo-container{
  box-shadow: 0px 0px 5px 2px rgba(255, 255, 255,0.35);
-webkit-box-shadow: 0px 0px 5px 2px rgba(255, 255, 255,0.35);
-moz-box-shadow: 0px 0px 5px 2px rgba(255,255,255,0.35);
}

.popup-price-link:hover{
  color: #ffffff;;
}

.rating-reviews{
  font-size:8px;
  display: inline-block;
  position: relative;
  top: -1px;
  margin-left:3px;
  margin-top: -2px;
}


.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #333333;
/*  position: absolute;*/
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -14px 0 0 -4px;
  
}

.inner-pin:hover{
  box-shadow: 0px 0px 15px 4px rgba(255, 255, 255,0.35);
-webkit-box-shadow: 0px 0px 15px 4px rgba(255, 255, 255,0.35);
-moz-box-shadow: 0px 0px 15px 4px rgba(255,255,255,0.35);
}

.custom-icon{
    font-size: 15px;
    margin-left: -7px;
  }
  /*
 .pin:after {
    content: '';
    width: 9px;
    height: 9px;
    margin:18px -2px -2px 21px;
    background: #000000;
    position: absolute;
    transform: rotate(45deg);
   /* border-radius: 50%; */
 /*}*/  

</style>

<style>
.leaflet-popup-tip{
  height: 13px !important;
  display: none !important;
}

.leaflet-popup-content-wrapper{
  background: rgba(0,0,0,0.45) !important;
  padding: 0px !important; 
  border-radius: 5px !important;
 }

 .leaflet-popup-content-wrapper, .leaflet-popup-tip{
   box-shadow: 1px 1px 11px 2px rgba(0,0,0,0.55) !important;
 }

.leaflet-popup-content{
   margin: 0px !important;
 }

.ft-placemarker-container {
  width: 160px !important;
  height: 160px !important;
  border-radius: 5px !important;
 }

</style>